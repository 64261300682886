import React from "react";

import Button from "../../atoms/Button/Button";
import Image from "../../atoms/Image/Image";
import ParseText from "../../atoms/ParseText/ParseText";

import { StyledText } from "../../atoms/StyledText/StyledText";
import {
  StyledAboutUsFirstSection,
  StyledDescWrapper,
  StyledImageWrapper,
  StyledParseText,
} from "./StyledAboutUsFirstSection";

const AboutUsFirstSection = ({ title, desc, btn, image }) => {
  return (
    <>
      <StyledAboutUsFirstSection>
        <StyledDescWrapper>
          <StyledText
            hasdeclaredfontsize="clamp(22px,2.292vw,44px)"
            hasdeclaredfontweight="700"
          >
            {title}
          </StyledText>
          <StyledParseText>
            <ParseText text={desc} />
          </StyledParseText>
          <Button text={btn} where={"/kontakt"} className="about-us-btn" />
        </StyledDescWrapper>
        <StyledImageWrapper>
          <Image image={image} />
        </StyledImageWrapper>
      </StyledAboutUsFirstSection>
    </>
  );
};

export default AboutUsFirstSection;
