import React from "react";
import { graphql } from "gatsby";

import AboutUsFirstSection from "../components/organisms/AboutUsFirstSection/AboutUsFirstSection";
import Banner from "../components/molecules/Banner/Banner";
import Container from "../components/atoms/Container/Container";
import OurRealizations from "../components/organisms/OurRealizations/OurRealizations";
import AboutUseSecondSection from "../components/organisms/AboutUseSecondSection/AboutUseSecondSection";
import HelmetTemplate from "../components/molecules/HelmetTemplate/HelmetTemplate";

const AboutUs = ({
  data: {
    datoCmsONasStrona: {
      tytuStrony,
      tytuPoPrawo,
      opisPoLewo,
      zdjCieGWne,
      zdjCiePoLewo,
      zdjCiePoPrawo,
      tytuPoLewo,
      tekstPrzyciskuPoPrawo,
      tekstPrzycisku,
      opisPoPrawo,
    },
  },
}) => {
  return (
    <main>
      <HelmetTemplate title="O nas" desc="Historia firmy Kon-stal" />
      <Container>
        <Banner text={tytuStrony} image={zdjCieGWne} />
        <AboutUsFirstSection
          image={zdjCiePoPrawo}
          title={tytuPoLewo}
          desc={opisPoLewo}
          btn={tekstPrzycisku}
        />
        <AboutUseSecondSection
          image={zdjCiePoLewo}
          title={tytuPoPrawo}
          btn={tekstPrzyciskuPoPrawo}
          desc={opisPoPrawo}
        />
        <OurRealizations centerText weightText />
      </Container>
    </main>
  );
};

export default AboutUs;

export const query = graphql`
  query aboutUsQuery {
    datoCmsONasStrona {
      tytuStrony
      tytuPoPrawo
      tytuPoLewo
      tekstPrzyciskuPoPrawo
      tekstPrzycisku
      opisPoPrawo
      opisPoLewo
      zdjCieGWne {
        alt
        title
        gatsbyImageData
      }
      zdjCiePoLewo {
        alt
        title
        gatsbyImageData
      }
      zdjCiePoPrawo {
        alt
        title
        gatsbyImageData
      }
    }
  }
`;
