import React from "react";

import Button from "../../atoms/Button/Button";
import Image from "../../atoms/Image/Image";
import ParseText from "../../atoms/ParseText/ParseText";

import { StyledText } from "../../atoms/StyledText/StyledText";
import {
  StyledAboutUseSecondSection,
  StyledDescWrapper,
  StyledImageWrapper,
  StyledParseText,
} from "./StyledAboutUseSecondSection";

const AboutUseSecondSection = ({ image, title, btn, desc }) => {
  return (
    <StyledAboutUseSecondSection>
      <StyledImageWrapper>
        <Image image={image} />
      </StyledImageWrapper>
      <StyledDescWrapper>
        <StyledText
          hasdeclaredfontsize="clamp(22px,2.292vw,44px)"
          hasdeclaredfontweight="700"
        >
          {title}
        </StyledText>
        <StyledParseText>
          <ParseText text={desc} />
        </StyledParseText>
        <Button text={btn} where={"/oferta"} className="about-us-btn" />
      </StyledDescWrapper>
    </StyledAboutUseSecondSection>
  );
};

export default AboutUseSecondSection;
