import styled from "styled-components";

export const StyledAboutUseSecondSection = styled.div`
  width: 100%;
  padding: 44px clamp(22px, 9.583vw, 184px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: clamp(20px, 3.125vw, 60px);
  background-color: #faf7f1;

  @media only screen and (max-width: 992px) {
    padding-left: 22px;
    padding-right: 22px;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const StyledDescWrapper = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: flex-end;

  @media only screen and (max-width: 768px) {
    width: 100%;
    align-items: flex-start;
  }
`;

export const StyledImageWrapper = styled.div`
  width: 40%;
  height: 100%;

  .gatsby-image-wrapper {
    height: 100%;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 300px;
  }
`;

export const StyledParseText = styled.div`
  font: 500 clamp(16px, 1.102vw, 20px) / 1.35em Poppins;
  text-align: right;

  @media only screen and (max-width: 768px) {
    text-align: left;
  }
`;
